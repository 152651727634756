import { call, put, all } from "redux-saga/effects";
import qs from 'query-string';
import { toast } from 'react-toastify';

import actions, { actionTypes } from "../../../shell/actions";
import { NextServerAPI } from '../../../utils';

export function* getBrands(action) {
  const { category, additionalInformation, token } = action.payload;

  yield put(actions.loadingBrands({ loading: true }));

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/category/${category}/brands/?additionalInformation=${qs.stringify(additionalInformation)}`,
      ...token ? { AuthorizationToken: `bearer ${token}` } : {},
    });

    yield put(actions.loadedBrands({ response }));
  }
  catch (e) {
    console.error(e);
  }
}

export function* getPlans(action) {
  const { query, token } = action.payload;

  yield put(actions.loadingPlans({ loading: true }));

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/plans/?${query}`,
      ...token ? { AuthorizationToken: `bearer ${token}` } : {},
    });

    if (response.data && response.data.plans) {
      yield put(actions.loadedPlans({ response }));
    } else {
      yield put(actions.errorLoadingPlans({ errorMessage: response.data.errorMessage }));
    }
  }
  catch (e) {
    const errorMessage = 'Some thing went wrong, please try again';
    toast.error(errorMessage);
    console.error(e);
  }
}

export function* getPlansAndProviders(action) {
  const { token, compareItems, members, year, namePlan, sumInsured, geolocation, budget } = action.payload;

  yield put(actions.loadingPlansAndProviders({ loading: true }))

  try {
    const plans = yield all(compareItems.map(plan => call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/plans/?${qs.stringify({
        year,
        planId: plan.id,
        member: members,
        name: namePlan,
        ...(plan.additionalBenefits ? { additional_benefits: JSON.stringify(plan.additionalBenefits) } : {}),
        ...(sumInsured ? { sum_insured: Math.trunc(sumInsured) } : {}),
        ...(budget ? { budget } : {}),
        ...(plan.term ? { term: plan.term } : {}),
      })}`,
      ...token ? { AuthorizationToken: `bearer ${token}` } : {},
    })));

    const providers = yield all(plans.map(plan => call(NextServerAPI, {
      method: 'GET',
      path: `/api/kronos/insurance-provider/product/?productId=${plan.data.product.id}`
    })));

    // let nearbyProviders;
    // if (geolocation) {
    //   const { latitude, longitude } = JSON.parse(geolocation);
    //   let brand = '';

    //   if (namePlan) {
    //     const namePlanList = namePlan.split('_');
    //     if (namePlanList.length > 0) {
    //       brand = namePlanList[0];
    //     }
    //   }

    //   // remove: nearby provider
    //   nearbyProviders = yield all(plans.map(plan => call(NextServerAPI, {
    //     method: 'GET',
    //     path: `/api/kronos/nearby-providers/?productId=${plan.data.product.id}&${qs.stringify({
    //       latitude,
    //       longitude,
    //       category: namePlan ? 'auto' : 'health',
    //       ...(brand && { brand }),
    //     })}`,
    //   })));
    // }

    yield put(actions.loadedPlansAndProviders({ plans, providers}))
  }
  catch (e) {
    console.error(e);
  }
}

export function* getInsuranceProviders(action) {
  const { plans } = action.payload;

  yield put(actions.loadingInsuranceProviders({ loading: true }))

  try {
    const response = yield all(plans.map(plan => call(NextServerAPI, {
      method: 'GET',
      path: `/api/kronos/insurance-provider/product/?productId=${plan.product.id}`
    })));

    yield put(actions.loadedInsuranceProviders({ response }))
  }
  catch (e) {
    console.error(e);
  }
}

export function* getAdditionalBenefits(action) {
  const { query } = action.payload;

  yield put(actions.loadingAdditionalBenefits());

  try {
    const additionalBenefits = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/additional-benefits/?${qs.stringify(query)}`
    });

    yield put(actions.loadedAdditionalBenefits({ additionalBenefits }));
  } catch (error) {
    yield put(actions.errorLoadingQuotationHistory({ errorObject: error }));
  }
}

export function* getPlanAndInsuranceProviders(action) {
  const { planId } = action.payload;

  yield put(actions.loadingPlanAndInsuranceProviders());

  try {
    const plan = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/plans/?planId=${planId}`,
    });

    const insuranceProviders = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/kronos/insurance-provider/product/?productId=${plan.data.product.id}`,
    });

    yield put(actions.loadedPlanAndInsuranceProviders({ plan, insuranceProviders }));
  } catch (error) {
    yield put(actions.errorLoadingPlanAndInsuranceProviders());
  }
}

export function* getBpjsProduct(action) {
  yield put(actions.loadingBpjsProduct());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/digital/bpjs/`,
    });

    yield put(actions.loadedBpjsProduct({ response }));
  } catch (error) {
    yield put(actions.errorLoadingBpjsProduct());
  }
}

export function* getPlnProduct(action) {
  const { plnType } = action.payload;

  yield put(actions.loadingPlnProduct());

  try {
    const response = yield call(NextServerAPI, {
      method: 'GET',
      path: `/api/products/digital/pln/?plnType=${plnType}`,
    });

    yield put(actions.loadedPlnProduct({ response }));
  } catch (error) {
    yield put(actions.errorLoadingPlnProduct());
  }
}

export default [
  [actionTypes.GET_BRANDS, getBrands],
  [actionTypes.GET_PLANS, getPlans],
  [actionTypes.GET_PLANS_AND_PROVIDERS, getPlansAndProviders],
  [actionTypes.GET_INSURANCE_PROVIDERS, getInsuranceProviders],
  [actionTypes.GET_ADDITIONAL_BENEFITS, getAdditionalBenefits],
  [actionTypes.GET_PLAN_AND_INSURANCE_PROVIDERS, getPlanAndInsuranceProviders],
  [actionTypes.GET_BPJS_PRODUCT, getBpjsProduct],
  [actionTypes.GET_PLN_PRODUCT, getPlnProduct],
];
